export const environment = {
    "production": false,
    "serviceName": "CoverPortal",
    "env": "dev",
    "amplify": {
        "Auth": {
            "mandatorySignIn": true,
            "region": "us-west-2",
            "userPoolId": "us-west-2_S8pOx97Qa",
            "identityPoolId": "",
            "userPoolWebClientId": "ksoti2tmmp5ufinn31u44k22d",
            "cookieStorage": {
                "domain": ".padev.studio",
                "secure": true,
                "path": "/",
                "expires": 7,
                "sameSite": "lax"
            },
            "oauth": {
                "domain": "auth.padev.studio",
                "redirectSignIn": "https://cover.padev.studio/",
                "redirectSignOut": "https://cover.padev.studio/",
                "responseType": "code"
            }
        }
    },
    "apiBaseUrls": {
        "cmf": "https://cmf.padev.studio/api",
        "rmf": "https://rmf.precision-autonomy.com/dev",
        "idf": "https://idf.padev.studio/api",
        "pay": "https://pay.padev.studio/api",
        "loginPortal": "https://login.padev.studio"
    }
}